<template>
  <b-form-group
    label="Şase No"
    label-for="chassis"
  >
    <validation-provider
      #default="{ errors }"
      name="Şase No"
      :rules="isRequired? 'required' : null"
    >
      <b-form-input
        id="chassis"
        v-model="car.chassis"
        placeholder="Şase No"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'Chasis',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
